import { useState } from 'react';
import { Box, IconButton, Link, Menu, Stack, Typography } from '@mui/material';
import { COLOR_ACCENT, COLOR_BG, COLOR_PRIMARY, COLOR_WH, Icons, TRANSITION_300 } from 'shared';
import { navLinks, socials } from 'widgets/layout/constants';

export const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box
      sx={{
        display: {
          md: 'none',
          xs: 'block',
        },
      }}
    >
      <IconButton
        onClick={handleOpen}
        color="secondary"
        sx={{
          borderColor: COLOR_ACCENT,
        }}
      >
        <Icons.Menu />
      </IconButton>
      <Menu
        onClose={handleClose}
        open={isOpen}
        marginThreshold={0}
        PaperProps={{
          sx: {
            m: 0,
            borderRadius: 0,
            top: '0 !important',
            left: '0 !important',
            p: 3,
            pt: 4,
            maxWidth: '100vw',
            width: '100%',
            maxHeight: '100vh',
            height: '100%',
            background: COLOR_BG,
          },
        }}
        MenuListProps={{
          sx: {
            p: 0,
            gap: 0,
            height: '100%',
          },
        }}
        sx={{ display: { md: 'none' } }}
      >
        <Stack direction="row" justifyContent="flex-end">
          <IconButton
            onClick={handleClose}
            color="secondary"
            className="rounded"
            sx={{
              width: 48,
              height: 48,
            }}
          >
            <Icons.Close
              sx={{
                color: COLOR_WH,
              }}
            />
          </IconButton>
        </Stack>
        <Stack flexGrow={1}>
          <Stack component="nav" alignItems="center" spacing={4.5} mt={5} flexGrow={1}>
            {navLinks.map((link) => {
              return (
                <Typography
                  onClick={handleClose}
                  className="center"
                  key={link.path}
                  component={Link}
                  href={link.path}
                  target="_blank"
                  rel="noreferrer"
                  variant="h4"
                  fontSize={30}
                  sx={{
                    transition: TRANSITION_300,
                    textDecoration: 'none',
                    '&:hover, &.active': {
                      color: COLOR_ACCENT,
                    },
                  }}
                >
                  {link.title}
                </Typography>
              );
            })}
          </Stack>
          <Typography variant="body2" className="center">
            Copyright © 2023 CratD2C
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={4} mt={4}>
            {socials.map(({ Icon, url }) => {
              return (
                <a key={url} rel="noreferrer" href={url} target="_blank">
                  <Icon
                    sx={{
                      color: COLOR_PRIMARY,
                    }}
                  />
                </a>
              );
            })}
          </Stack>
        </Stack>
      </Menu>
    </Box>
  );
};
