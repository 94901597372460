import { Box, Card, Grid, styled, Typography } from '@mui/material';
import { useBreakpoints } from 'shared';

import { partners } from './constants';

const StyledCard = styled(Card)(({ theme }) => ({
  height: 72,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
}));

export const Partners = () => {
  const { isDownMdLayout } = useBreakpoints();

  return (
    <Box component="section">
      <Grid container alignItems="end" columnSpacing={1} rowSpacing={{ xs: 1, md: 6 }}>
        {partners.map(({ id, subtitle, image, maxHeight }) => (
          <Grid item container key={id} xs={6} md={4} lg={2} rowGap={1}>
            {subtitle && !isDownMdLayout && <Typography className="secondary">{subtitle}</Typography>}
            <StyledCard>
              <Box
                component="img"
                src={image}
                sx={{ width: '100%', height: '100%', maxHeight, objectFit: 'contain' }}
              />
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
